import i18n from "i18next";
import { initReactI18next } from "react-i18next";

///// {t("back_to_menu")}
const resources = {
  en: {
    translation: {
      wellcome_header: "Welcome WISer, ",
      back_to_menu:"Back to Homepage",
      title:"Title",
      content:"Content",
      preview:"Preview",
      language: "Language",
      picture:"Add a picture",
      term:"I commit to uploading photos with positive content that do not violate cultural norms or traditions!",
      post:"Post",
      cancel:"Cancel",
      Participants:"Participants",
      Participants_count:"others have joined the challenge",
      Participants_and:"and",
      Participants_empty:"No submissions yet.",
      Info:"Information",
      Info_deadline:"Submission Deadline",
      Info_deadline_còn:"",
      Info_deadline_ngày:"day left",
      Info_number_submission:"Submissions",
      Info_number_submission_unlimited:"Unlimited",
      Info_number_submission_term:"Term and conditions",
      loginbox_title: "Enter your ID",
      loginbox_next: "Next",
      loginbox_verifyname: "Choose your name",
      loginbox_back:"Back",
      toast_invalid_id: "Invalid ID or an error occurred!",
      toast_auth_error: "Authentication error: No user ID found!",
      toast_name_verification_error: "Name verification failed!",
      toast_no_auth_info: "No authentication information found. Please try again!",
      welcome_banner_01: "The vibrant atmosphere of Tet, filled with the colors of spring, has spread throughout Wellspring Hanoi! This is the perfect time for every WISer to pause, reflect on the past year, rejuvenate, spread love, and create truly meaningful Tet memories.",
      welcome_banner_02: "Are you ready for the 6 Challenges? A place to capture every vibrant moment filled with the true spirit of the 2025 Spring Festival - Home is where Tet lives in everyone’s heart!",
      welcome_banner_03: "Get ready to capture your most beautiful moments and welcome an unforgettable New Year!",
      today_challenges:"Today’s Challenge",
      today_Challenges_còn:"",
      today_Challenges_ngày:"day left",
      today_Challenges_bài_dự_thi:"submissions",
      today_Challenges_Challenge:"Challenge",
      today_Challenges_today_Challenges: "Join the Challenge",
      prizes_banner: "Prize Structure",
      prizes_des:"06 prizes for the photos with the most hearts in each challenge.",
      prizes_des_02:"",
      featured_submissions: "Featured Submissions",
      featured_submissions_all: "All",
      featured_submissions_Challenge: "Challenge",
      featured_submissions_Challenge_all:"All Challenge",
      featured_submissions_art_word:"Artwork",
      my_submission:"My submission",
      my_submission_empty:"You have no submissions yet",
      submissions:"Submissions",
      submissions_filtered:"Filtered By",
      submissions_filtered_votes:"Vote",
      submissions_filtered_Newest:"Newest",
      submissions_filtered_Oldest:"Oldest",
      submissions_filtered_My_Submission:"My submission",
      ending:"We will reunite after the Tet holiday to continue conquering new challenges on our educational journey together. We hope our students, teachers, and staff return to school with renewed enthusiasm, ready for productive days of learning and working.Wishing all WiSers a peaceful, joyful, and meaningful Tet season with family and friends, because “Home is where Tet lives in everyone’s heart”! ",
    },
  },
  vi: {
    translation: {
      wellcome_header: "Chào mừng WISer, ",
      back_to_menu:"Quay lại trang chủ",
      language: "Ngôn ngữ",
      title:"Tiêu đề",
      content:"Nội dung",
      preview:"Xem trước ảnh",
      picture:"Thêm ảnh",
      term:"Tôi cam kết đăng những hình ảnh mang nội dung tích cực, không vi phạm thuần phong mỹ tục.",
      post:"Đăng bài",
      cancel:"Huỷ",
      Participants:"Người tham gia",
      Participants_count:"người khác đã tham gia thử thách",
      Participants_and:"và",
      Participants_empty:"Chưa có bài dự thi nào.",
      Info:"Thông tin chính",
      Info_deadline:"Hạn cuối nộp bài",
      Info_deadline_còn:"Còn",
      Info_deadline_ngày:"ngày",
      Info_number_submission:"Số bài dự thi",
      Info_number_submission_unlimited:"Không giới hạn",
      Info_number_submission_term:"Điều khoản và điều kiện",
      loginbox_title: "Nhập ID của bạn",
      loginbox_next: "Tiếp tục",
      loginbox_verifyname: "Chọn tên của bạn",
      loginbox_back:"Quay lại",
      toast_invalid_id: "ID không hợp lệ hoặc có lỗi xảy ra!",
      toast_auth_error: "Lỗi xác thực: Không tìm thấy userId!",
      toast_name_verification_error: "Lỗi xác thực tên!",
      toast_no_auth_info: "Không tìm thấy thông tin xác thực. Vui lòng thử lại!",
      welcome_banner_01: "Không khí tết rộn ràng, ngập tràn sắc xuân đã lan tỏa khắp Wellspring Hanoi! Đây là lúc để mỗi WISer dừng lại một chút, nhìn lại chặng đường một năm đã qua, làm mới chính mình, lan tỏa yêu thương và tạo nên những kỷ niệm Tết thật ý nghĩa.",
      welcome_banner_02: "Các WISers đã sẵn sàng với 6 Thử thách Ngày Tết chưa? Nơi lưu giữ từng khoảnh khắc rực rỡ, đậm chất tinh thần Lễ hội Mùa Xuân 2025 - Nhà là tết lớn trong Tim!",
      welcome_banner_03: "Hãy chuẩn bị những khung hình đẹp nhất và cùng nhau chào đón một mùa Tết thật đáng nhớ!",
      today_challenges:"Thử Thách Hôm Nay",
      today_Challenges_còn:"Còn",
      today_Challenges_ngày:"ngày",
      today_Challenges_bài_dự_thi:"bài dự thi",
      today_Challenges_Challenge:"Thử thách",
      today_Challenges_today_Challenges: "Tham gia thử thách",
      prizes_banner: "Cơ cấu giải thưởng",
      prizes_des:"06 giải dành cho bức ảnh được thả tim nhiều nhất trong từng thử thách.",
      prizes_des_02:"",
      featured_submissions: "BÀI DỰ THI NỔI BẬT",
      featured_submissions_all: "Tất cả",
      featured_submissions_Challenge:"Thử thách",
      featured_submissions_Challenge_all:"Tất cả thử thách",
      featured_submissions_art_word:"Tác phẩm",
      my_submission:"BÀI DỰ THI CỦA TÔI",
      my_submission_empty:"Bạn chưa có bài dự thi nào",
      submissions:"BÀI DỰ THI",
      submissions_filtered:"Sắp xếp theo",
      submissions_filtered_votes:"Lượt bình chọn",
      submissions_filtered_Newest:"Mới nhất",
      submissions_filtered_Oldest:"Cũ nhất",
      submissions_filtered_My_Submission:"Bài dự thi của tôi",
      ending:"Chúng ta sẽ gặp lại nhau sau kỳ nghỉ Tết, cùng nhau tiếp tục chinh phục những thử thách mới trên con đường học vấn. Mong các em Học sinh, các Giáo viên, Nhân viên trở lại trường với tinh thần hứng khởi, sẵn sàng cho những ngày học tập và làm việc hiệu quả. Chúc các WISer một mùa Tết an lành, vui vẻ, đầy ý nghĩa bên gia đình và bạn bè!",

    },
  },
};

i18n.use(initReactI18next).init({
  resources,
  lng: "vi", // Mặc định tiếng Việt
  fallbackLng: "en",
  interpolation: {
    escapeValue: false,
  },
});

export default i18n;